import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchOrderById, updateOrder } from "../../services/orderService";
import Notification from "../../components/Notification/Notification";
import OrderTable from "./components/OrderTable";
import { Typography, CircularProgress, IconButton, TextField, Select, MenuItem } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PrintIcon from "@mui/icons-material/Print";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { getStatusLabel } from "../../utils/statusUtils";
import "./OrderPage.css";

const OrderPage = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    severity: "info",
    message: "",
  });

  useEffect(() => {
    const loadOrder = async () => {
      try {
        const fetchedOrder = await fetchOrderById(orderId);
        setOrder(fetchedOrder);
        setFormData({
          orderNumber: fetchedOrder.orderNumber || "",
          service: fetchedOrder.service || "",
          price: fetchedOrder.price?.toString() || "",
          comment: fetchedOrder.comment || "",
          orderStatus: fetchedOrder.orderStatus || "CREATED",
        });
      } catch (error) {
        setNotification({
          open: true,
          severity: "error",
          message: "Не удалось загрузить данные заказа.",
        });
      } finally {
        setLoading(false);
      }
    };

    loadOrder();
  }, [orderId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      await updateOrder(orderId, formData);
      setOrder((prev) => ({ ...prev, ...formData }));
      setNotification({
        open: true,
        severity: "success",
        message: "Заказ успешно обновлён!",
      });
      setIsEditing(false);
    } catch (error) {
      setNotification({
        open: true,
        severity: "error",
        message: "Ошибка при обновлении заказа.",
      });
    } finally {
      setSaving(false);
    }
  };

  const handleCancelEdit = () => {
    setFormData({
      orderNumber: order.orderNumber || "",
      service: order.service || "",
      price: order.price?.toString() || "",
      comment: order.comment || "",
      orderStatus: order.orderStatus || "CREATED",
    });
    setIsEditing(false);
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return (
      <div className="order-loader-container">
        <CircularProgress className="order-loader" />
        <Typography>Загрузка данных заказа...</Typography>
      </div>
    );
  }

  if (!order) {
    return (
      <Notification
        severity="error"
        message="Заказ не найден."
        open={notification.open}
        onClose={handleCloseNotification}
      />
    );
  }

  return (
    <div className="order-page-container">
      <Notification
        severity={notification.severity}
        message={notification.message}
        open={notification.open}
        onClose={handleCloseNotification}
      />

      <div className="order-header">
        <Typography variant="h5" className="order-page-title">
          Заказ #{order.orderNumber}
        </Typography>
        <div className="header-buttons">
          {isEditing ? (
            <>
              <IconButton color="primary" onClick={handleSave} disabled={saving} title="Сохранить">
                {saving ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
              </IconButton>
              <IconButton color="secondary" onClick={handleCancelEdit} title="Отменить">
                <CancelIcon />
              </IconButton>
            </>
          ) : (
            <IconButton color="secondary" onClick={() => setIsEditing(true)} title="Редактировать">
              <EditNoteIcon />
            </IconButton>
          )}
          <IconButton color="primary" onClick={handlePrint} title="Печать">
            <PrintIcon />
          </IconButton>
        </div>
      </div>

      <OrderTable
        title="Общая информация"
        rows={[
          {
            label: "Статус заказа",
            value: isEditing ? (
              <Select
                name="orderStatus"
                value={formData.orderStatus}
                onChange={handleInputChange}
                fullWidth
                size="small"
                style={{ minHeight: 40 }}
              >
                <MenuItem value="CREATED">{getStatusLabel("CREATED")}</MenuItem>
                <MenuItem value="IN_PROGRESS">{getStatusLabel("IN_PROGRESS")}</MenuItem>
                <MenuItem value="DONE">{getStatusLabel("DONE")}</MenuItem>
                <MenuItem value="STOPPED">{getStatusLabel("STOPPED")}</MenuItem>
              </Select>
            ) : (
              getStatusLabel(order.orderStatus)
            ),
          },
          {
            label: "Услуга",
            value: isEditing ? (
              <TextField
                name="service"
                value={formData.service}
                onChange={handleInputChange}
                fullWidth
                size="small"
              />
            ) : (
              order.service || "Не указана"
            ),
          },
          {
            label: "Цена",
            value: isEditing ? (
              <TextField
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                fullWidth
                size="small"
              />
            ) : (
              `${order.price || 0} руб.`
            ),
          },
          {
            label: "Комментарий",
            value: isEditing ? (
              <TextField
                name="comment"
                value={formData.comment}
                onChange={handleInputChange}
                fullWidth
                size="small"
              />
            ) : (
              order.comment || "Без комментария"
            ),
          },
        ]}
      />

      <OrderTable
        title="Клиент"
        rows={[
          { label: "ФИО", value: `${order.client.lastName} ${order.client.firstName} ${order.client.middleName || ""}` },
          { label: "Телефон", value: order.client.phoneNumber },
          { label: "Email", value: order.client.email || "Не указан" },
        ]}
      />

      <OrderTable
        title="Автомобиль"
        rows={[
          { label: "Марка", value: order.car.brand },
          { label: "Модель", value: order.car.model },
          { label: "Гос. номер", value: order.car.licensePlate || "Не указан" },
          { label: "VIN", value: order.car.vin || "Не указан" },
          { label: "Год", value: order.car.year || "Не указан" },
          { label: "Цвет", value: order.car.color || "Не указан" },
          { label: "Пробег", value: `${order.car.mileage} км` },
        ]}
      />

      <OrderTable
        title="Менеджер"
        rows={[
          { label: "ФИО менеджера", value: order.manager ? `${order.manager.lastName} ${order.manager.firstName}` : "Не назначен" },
        ]}
      />
    </div>
  );
};

export default OrderPage;