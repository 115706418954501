import errorMessages from "./errorMessages";

/**
 * Обработчик ошибок API.
 * Обрабатывает ошибки HTTP, включая статус и сообщение.
 */
export const handleApiError = async (response) => {
  let errorMessage = errorMessages[response.status] || errorMessages.DEFAULT;

  try {
    const errorData = await response.json();
    if (errorData && errorData.message) {
      errorMessage = errorData.message; // Используем сообщение с сервера
    }
  } catch {
    // Если тело ответа не JSON, оставляем стандартное сообщение
  }

  console.error(`API Error [${response.status}]: ${errorMessage}`);

  // Создаём объект ошибки и добавляем статус
  const error = new Error(errorMessage);
  error.status = response.status; // Добавляем статус к ошибке
  throw error;
};

/**
 * Базовая функция для выполнения запросов к API.
 */
export const fetchWithAuth = async (endpoint, options = {}) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
    credentials: "include",
    ...options,
  });

  if (!response.ok) {
    await handleApiError(response); // Обрабатываем ошибку
  }

  return response.json();
};