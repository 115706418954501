export const apiClient = async (endpoint, { method = "GET", body, headers = {}, ...options } = {}) => {
  const url = `${process.env.REACT_APP_API_URL}${endpoint}`;

  const response = await fetch(url, {
    method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    body: body ? JSON.stringify(body) : null,
    credentials: "include",
    ...options,
  });

  if (!response.ok) {
    const error = await response.json().catch(() => ({ message: "Ошибка API" }));
    throw new Error(error.message || "Не удалось выполнить запрос");
  }

  return response.json();
};