import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import errorMessages from "../../utils/errorMessages";
import "../ErrorPage/ErrorPage.css";

const ErrorPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const message = location.state?.message || errorMessages.DEFAULT;

  const handleGoToLogin = () => {
    navigate("/");
  };

  return (
    <div className="error-page-container">
      <Typography variant="h4" gutterBottom>
        Ошибка доступа
      </Typography>
      <Typography variant="body1" gutterBottom>
        {message}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoToLogin}
        className="error-page-button"
      >
        Перейти на страницу входа
      </Button>
    </div>
  );
};

export default ErrorPage;