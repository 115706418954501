import { fetchUserById, updateUserById } from "../../../services/userService";
import { fetchCarById, removeCarFromUser, addCarToUser } from "../../../services/carService";

/**
 * Загрузка данных пользователя и автомобилей.
 */
export const loadUserData = async (id, setUser, setFormData, setCarDetails, setNotification, setLoading) => {
  try {
    const fetchedUser = await fetchUserById(id);
    setUser(fetchedUser);
    setFormData(fetchedUser);

    const cars = await Promise.all(
      fetchedUser.carIds.map(async (carId) => {
        const car = await fetchCarById(carId);
        return car;
      })
    );
    setCarDetails(cars);
  } catch (error) {
    setNotification({
      open: true,
      severity: "error",
      message: "Не удалось загрузить данные пользователя.",
    });
  } finally {
    setLoading(false);
  }
};

/**
 * Сохранение изменений данных пользователя.
 */
export const handleSaveUserData = async (id, formData, setUser, setNotification, setIsEditing) => {
  try {
    await updateUserById(id, formData);
    setUser(formData);
    setNotification({
      open: true,
      severity: "success",
      message: "Данные пользователя успешно обновлены.",
    });
    setIsEditing(false);
  } catch (error) {
    setNotification({
      open: true,
      severity: "error",
      message: "Не удалось обновить данные пользователя.",
    });
  }
};

/**
 * Отмена изменений и возврат к исходным данным.
 */
export const handleCancelEdit = (user, carDetails, setFormData, setCarDetails, setIsEditing) => {
  setFormData(user);
  setCarDetails(user.carIds.map((carId) => carDetails.find((car) => car.id === carId)));
  setIsEditing(false);
};

/**
 * Удаление автомобиля.
 */
export const handleDeleteCar = async (userId, carId, setFormData, setCarDetails, setNotification) => {
  try {
    await removeCarFromUser(userId, carId);
    setFormData((prev) => ({
      ...prev,
      carIds: prev.carIds.filter((id) => id !== carId),
    }));
    setCarDetails((prev) => prev.filter((car) => car.id !== carId));
    setNotification({
      open: true,
      severity: "success",
      message: "Автомобиль успешно удалён.",
    });
  } catch (error) {
    setNotification({
      open: true,
      severity: "error",
      message: "Не удалось удалить автомобиль. Попробуйте позже.",
    });
  }
};

/**
 * Добавление автомобиля пользователю.
 */
export const handleAddCarToUser = async (userId, carId, setFormData, setCarDetails, setNotification) => {
  try {
    await addCarToUser(userId, carId);
    const car = await fetchCarById(carId);

    setFormData((prev) => ({
      ...prev,
      carIds: [...prev.carIds, carId],
    }));
    setCarDetails((prev) => [...prev, car]);
    setNotification({
      open: true,
      severity: "success",
      message: "Автомобиль успешно добавлен.",
    });
  } catch (error) {
    setNotification({
      open: true,
      severity: "error",
      message: "Не удалось добавить автомобиль. Попробуйте позже.",
    });
  }
};