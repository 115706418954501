import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogActions, Button, Alert } from "@mui/material";

/**
 * Notification - компонент для отображения уведомлений.
 *
 * @param {string} severity - Тип уведомления ("success", "info", "warning", "error").
 * @param {string} message - Сообщение для отображения.
 * @param {boolean} open - Состояние открытия/закрытия уведомления.
 * @param {function} onClose - Функция закрытия уведомления.
 */
const Notification = ({ severity = "info", message, open, onClose }) => {
  if (!open) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent>
        <Alert severity={severity} variant="filled">
          {message}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          Ок
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Notification.propTypes = {
  severity: PropTypes.oneOf(["success", "info", "warning", "error"]),
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Notification;