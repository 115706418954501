import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchCarById, updateCar } from "../../services/carService";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Stack,
} from "@mui/material";
import "./CarPage.css";

const CarPage = () => {
  const { carId } = useParams();
  const [car, setCar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const loadCar = async () => {
      try {
        const carData = await fetchCarById(carId);
        setCar(carData);
        setFormData(carData);
      } catch (err) {
        setError("Не удалось загрузить данные об автомобиле.");
      } finally {
        setLoading(false);
      }
    };
    loadCar();
  }, [carId]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setFormData({ ...car });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      await updateCar(carId, formData);
      setCar(formData);
      setIsEditing(false);
    } catch (err) {
      setError("Ошибка при сохранении данных. Попробуйте снова.");
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Box className="loading-container">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className="error-line">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!car) {
    return (
      <Box className="empty-container">
        <Typography variant="h6" color="textSecondary">
          Информация об автомобиле недоступна.
        </Typography>
      </Box>
    );
  }

  return (
    <Box className="car-page-container">
      <Typography variant="h5" gutterBottom>
        {isEditing ? "Редактирование автомобиля" : "Информация об автомобиле"}
      </Typography>
      <form onSubmit={handleSave} className="car-page-form">
        <TextField
          name="brand"
          label="Марка"
          value={formData?.brand || ""}
          onChange={handleChange}
          variant="outlined"
          size="small"
          required
          fullWidth
          disabled={!isEditing}
        />
        <TextField
          name="model"
          label="Модель"
          value={formData?.model || ""}
          onChange={handleChange}
          variant="outlined"
          size="small"
          required
          fullWidth
          disabled={!isEditing}
        />
        <TextField
          name="licensePlate"
          label="Гос. номер"
          value={formData?.licensePlate || ""}
          onChange={handleChange}
          variant="outlined"
          size="small"
          required
          fullWidth
          disabled={!isEditing}
        />
        <TextField
          name="vin"
          label="VIN"
          value={formData?.vin || ""}
          onChange={handleChange}
          variant="outlined"
          size="small"
          required
          fullWidth
          disabled={!isEditing}
        />
        <TextField
          name="year"
          label="Год"
          value={formData?.year || ""}
          onChange={handleChange}
          variant="outlined"
          size="small"
          required
          fullWidth
          type="number"
          disabled={!isEditing}
        />
        <TextField
          name="color"
          label="Цвет"
          value={formData?.color || ""}
          onChange={handleChange}
          variant="outlined"
          size="small"
          required
          fullWidth
          disabled={!isEditing}
        />
        <TextField
          name="mileage"
          label="Пробег (км)"
          value={formData?.mileage || ""}
          onChange={handleChange}
          variant="outlined"
          size="small"
          required
          fullWidth
          type="number"
          disabled={!isEditing}
        />
        <Stack direction="row" spacing={2} className="car-page-buttons">
          {isEditing ? (
            <>
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleEditToggle}
                disabled={saving}
              >
                Отмена
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={saving}
              >
                {saving ? "Сохранение..." : "Сохранить"}
              </Button>
            </>
          ) : (
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleEditToggle}
            >
              Редактировать
            </Button>
          )}
        </Stack>
      </form>
    </Box>
  );
};

export default CarPage;