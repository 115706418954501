import React, { createContext, useState, useContext } from "react";

// Создаём контекст пользователя
const UserContext = createContext();

/**
 * Провайдер для предоставления данных о пользователе всему приложению.
 */
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Храним текущего пользователя

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};

export default UserContext;