import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createCar } from "../../services/carService";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Stack,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import Notification from "../../components/Notification/Notification";
import carBrandsAndModels from "../../data/carBrandsAndModels.json";
import carColors from "../../data/carColors.json";
import "./AddCarPage.css";

const filter = createFilterOptions();

const AddCarPage = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    brand: "",
    model: "",
    licensePlate: "",
    vin: "",
    year: "",
    color: "",
    mileage: "",
  });

  const [models, setModels] = useState([]);
  const [errors, setErrors] = useState({
    year: "",
    mileage: "",
  });
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    severity: "info",
    message: "",
  });

  const validateNumberField = (name, value) => {
    if (!/^\d*$/.test(value)) {
      return "Поле должно содержать только цифры";
    }
    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "year" || name === "mileage") {
      const error = validateNumberField(name, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error || "",
      }));
    }

    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleBrandChange = (newValue) => {
    if (typeof newValue === "string") {
      setFormData((prevData) => ({ ...prevData, brand: newValue }));
      setModels([]);
    } else if (newValue?.inputValue) {
      setFormData((prevData) => ({ ...prevData, brand: newValue.inputValue }));
      setModels([]);
    } else {
      setFormData((prevData) => ({ ...prevData, brand: newValue?.brand || "" }));
      setModels(newValue?.models || []);
    }
    setFormData((prevData) => ({ ...prevData, model: "" }));
  };

  const handleModelChange = (newValue) => {
    if (typeof newValue === "string") {
      setFormData((prevData) => ({ ...prevData, model: newValue }));
    } else if (newValue?.inputValue) {
      setFormData((prevData) => ({ ...prevData, model: newValue.inputValue }));
    } else {
      setFormData((prevData) => ({ ...prevData, model: newValue?.title || "" }));
    }
  };

  const handleColorChange = (newValue) => {
    if (typeof newValue === "string") {
      setFormData((prevData) => ({ ...prevData, color: newValue }));
    } else if (newValue?.inputValue) {
      setFormData((prevData) => ({ ...prevData, color: newValue.inputValue }));
    } else {
      setFormData((prevData) => ({ ...prevData, color: newValue?.title || "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { brand, model, licensePlate, vin, year, color, mileage } = formData;

    if (
      !brand.trim() ||
      !model.trim() ||
      !licensePlate.trim() ||
      !vin.trim() ||
      !year.trim() ||
      !color.trim() ||
      !mileage.trim()
    ) {
      setNotification({
        open: true,
        severity: "warning",
        message: "Пожалуйста, заполните все поля!",
      });
      return;
    }

    if (errors.year || errors.mileage) {
      setNotification({
        open: true,
        severity: "error",
        message: "Исправьте ошибки в полях!",
      });
      return;
    }

    if (!/^\d+$/.test(year) || !/^\d+$/.test(mileage)) {
      setNotification({
        open: true,
        severity: "error",
        message: "Год и пробег должны содержать только цифры!",
      });
      return;
    }

    try {
      setLoading(true);
      await createCar(formData);
      setNotification({
        open: true,
        severity: "success",
        message: "Автомобиль успешно добавлен!",
      });
    } catch (err) {
      setNotification({
        open: true,
        severity: "error",
        message: "Ошибка при добавлении автомобиля. Попробуйте снова.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNotificationClose = () => {
    if (notification.severity === "success") {
      navigate(-1);
    } else {
      setNotification({ ...notification, open: false });
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Box className="add-car-page-container">
      <Notification
        severity={notification.severity}
        message={notification.message}
        open={notification.open}
        onClose={handleNotificationClose}
      />

      <Typography variant="h6" className="add-car-title">
        Добавить автомобиль
      </Typography>
      <Box component="form" onSubmit={handleSubmit} className="add-car-form">
        <Autocomplete
          options={carBrandsAndModels.cars}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.brand
          }
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            if (inputValue && !options.some((option) => option.brand === inputValue)) {
              filtered.push({ inputValue, brand: inputValue, models: [] });
            }
            return filtered;
          }}
          onChange={handleBrandChange}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Марка" name="brand" fullWidth />
          )}
        />

        <Autocomplete
          options={models.map((model) => ({ title: model }))}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.title
          }
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            if (inputValue && !options.some((option) => option.title === inputValue)) {
              filtered.push({ inputValue, title: inputValue });
            }
            return filtered;
          }}
          onChange={handleModelChange}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Модель" name="model" fullWidth />
          )}
        />

        <Autocomplete
          options={carColors.colors.map((color) => ({ title: color }))}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.title
          }
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            if (inputValue && !options.some((option) => option.title === inputValue)) {
              filtered.push({ inputValue, title: inputValue });
            }
            return filtered;
          }}
          onChange={handleColorChange}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Цвет" name="color" fullWidth />
          )}
        />

        <TextField
          name="licensePlate"
          label="Гос. номер"
          value={formData.licensePlate}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          name="vin"
          label="VIN"
          value={formData.vin}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          name="year"
          label="Год"
          value={formData.year}
          onChange={handleChange}
          error={!!errors.year}
          helperText={errors.year}
          fullWidth
        />
        <TextField
          name="mileage"
          label="Пробег (км)"
          value={formData.mileage}
          onChange={handleChange}
          error={!!errors.mileage}
          helperText={errors.mileage}
          fullWidth
        />
        <Stack direction="row" spacing={2} className="button-stack">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            fullWidth
          >
            {loading ? <CircularProgress size={20} color="inherit" /> : "Сохранить"}
          </Button>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            fullWidth
          >
            Отмена
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default AddCarPage;