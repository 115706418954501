import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { fetchCurrentUser, searchUsers } from "../../services/userService";
import { searchCars } from "../../services/carService";
import { logout } from "../../auth/authService";
import {
  CircularProgress,
  Box,
  Typography,
  TextField,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
} from "@mui/material";
import "./ManagerPage.css";

const ManagerPage = () => {
  const navigate = useNavigate();

  // Состояния
  const [searchTerm, setSearchTerm] = useState("");       // Текст поиска
  const [searchType, setSearchType] = useState("client"); // Тип поиска (клиент/автомобиль)
  const [loading, setLoading] = useState(false);          // Индикатор загрузки
  const [results, setResults] = useState([]);             // Результаты поиска
  const [currentUser, setCurrentUser] = useState(null);   // Текущий пользователь

  // Загрузка текущего пользователя
  useEffect(() => {
    const loadCurrentUser = async () => {
      try {
        const user = await fetchCurrentUser();
        setCurrentUser(user);
      } catch {
        navigate("/error", {
          state: { message: "У вас нет доступа к этой странице." },
        });
      }
    };
    loadCurrentUser();
  }, [navigate]);

  /**
   * Обработчик поиска данных (клиенты или автомобили).
   * @param {string} query - Запрос для поиска.
   */
  const handleSearch = useCallback(
    async (query) => {
      if (!query.trim()) {
        setResults([]);
        return;
      }

      setLoading(true);

      try {
        let searchResults = [];
        if (searchType === "client") {
          const response = await searchUsers(query, 0, 10);
          searchResults = response.users.map((user) => ({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            middleName: user.middleName || "Не указано",
            phoneNumber: user.phoneNumber || "Не указан",
          }));
        } else if (searchType === "car") {
          const response = await searchCars(query, 0, 10);
          searchResults = response.cars.map((car) => ({
            id: car.id,
            brand: car.brand,
            model: car.model,
            licensePlate: car.licensePlate || "Не указан",
            vin: car.vin || "Не указан",
            color: car.color || "Не указан",
            mileage: car.mileage || "Не указан",
            year: car.year || "Не указан",
          }));
        }
        setResults(searchResults);
      } finally {
        setLoading(false);
      }
    },
    [searchType]
  );

  // Автоматическое выполнение поиска при изменении запроса
  useEffect(() => {
    if (searchTerm.trim()) {
      handleSearch(searchTerm);
    } else {
      setResults([]);
    }
  }, [searchTerm, handleSearch]);

  // Обработчик выхода
  const handleLogout = async () => {
    if (window.confirm("Вы действительно хотите выйти?")) {
      try {
        await logout();
        navigate("/", { replace: true });
      } catch (error) {
        console.error("Ошибка при логауте:", error);
      }
    }
  };

  // Переход на страницу добавления заказа
  const handleAddOrder = () => {
    navigate("/manager/add-order");
  };

  return (
    <Box className="manager-page">
      {/* Шапка страницы */}
      <Box className="manager-header">
        <Typography
          variant="h6"
          onClick={handleLogout}
          className="header-left"
        >
          {currentUser ? (
            `${currentUser.firstName} ${currentUser.lastName}`
          ) : (
            <CircularProgress size={20} color="inherit" />
          )}
        </Typography>
        <Button variant="contained" 
        className="add-order-button"
        onClick={handleAddOrder}>
          Добавить
        </Button>
      </Box>

      {/* Поисковая панель */}
      <Box className="content-container">
        {/* Выбор типа поиска */}
        <FormControl component="fieldset" className="search-type">
          <RadioGroup
            row
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <FormControlLabel
              value="client"
              control={<Radio />}
              label="Клиент"
            />
            <FormControlLabel value="car" control={<Radio />} label="Авто" />
          </RadioGroup>
        </FormControl>

        {/* Поле для ввода запроса */}
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          className="search-container"
        >
          <TextField
            fullWidth
            label={
              searchType === "client"
                ? "Поиск клиента по ФИО и телефону"
                : "Поиск автомобиля по VIN и номеру"
            }
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            size="small"
          />
        </Stack>
      </Box>

      {/* Результаты поиска */}
      <Box className="results-container">
        {loading ? (
          <Box className="loading-container">
            <CircularProgress />
          </Box>
        ) : results.length === 0 ? (
          <Typography className="no-results">
            {searchType === "client"
              ? "Клиенты не найдены"
              : "Автомобили не найдены"}
          </Typography>
        ) : (
          results.map((item) =>
            searchType === "client" ? (
              <Box key={item.id} className="result-preview">
                <Typography>
                  <strong>ФИО:</strong> {`${item.lastName} ${item.firstName} ${item.middleName}`}
                </Typography>
                <Typography>
                  <strong>Телефон:</strong> {item.phoneNumber}
                </Typography>
              </Box>
            ) : (
              <Box key={item.id} className="result-preview">
                <Typography>
                  <strong>Марка:</strong> {item.brand}
                </Typography>
                <Typography>
                  <strong>Модель:</strong> {item.model}
                </Typography>
                <Typography>
                  <strong>Гос.номер:</strong> {item.licensePlate}
                </Typography>
                <Typography>
                  <strong>VIN:</strong> {item.vin}
                </Typography>
                <Typography>
                  <strong>Цвет:</strong> {item.color}
                </Typography>
                <Typography>
                  <strong>Пробег:</strong> {item.mileage} км
                </Typography>
                <Typography>
                  <strong>Год:</strong> {item.year}
                </Typography>
              </Box>
            )
          )
        )}
      </Box>
    </Box>
  );
};

export default ManagerPage;