import React from "react";
import { TextField, Chip, Typography } from "@mui/material";
import { getRoleLabel } from "../../../utils/roleUtils";
import "./UserTable.css";

const UserTable = ({
  formData,
  carDetails,
  isEditing,
  handleChipClick,
  handleDeleteCar,
  setFormData,
  formatPhoneNumber,
  id,
  setNotification,
  setCarDetails,
}) => {
  const { lastName, firstName, middleName, phoneNumber, role, createdAt, updatedAt } = formData;

  return (
    <table className="user-table">
      <tbody>
        <tr>
          <th>Фамилия</th>
          <td>
            {isEditing ? (
              <TextField
                name="lastName"
                value={lastName || ""}
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                fullWidth
                size="small"
              />
            ) : (
              lastName || "Не указано"
            )}
          </td>
        </tr>
        <tr>
          <th>Имя</th>
          <td>
            {isEditing ? (
              <TextField
                name="firstName"
                value={firstName || ""}
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                fullWidth
                size="small"
              />
            ) : (
              firstName || "Не указано"
            )}
          </td>
        </tr>
        <tr>
          <th>Отчество</th>
          <td>
            {isEditing ? (
              <TextField
                name="middleName"
                value={middleName || ""}
                onChange={(e) => setFormData({ ...formData, middleName: e.target.value })}
                fullWidth
                size="small"
              />
            ) : (
              middleName || "Не указано"
            )}
          </td>
        </tr>
        <tr>
          <th>Телефон</th>
          <td>
            {isEditing ? (
              <TextField
                name="phoneNumber"
                value={phoneNumber || ""}
                onChange={(e) =>
                  setFormData({ ...formData, phoneNumber: formatPhoneNumber(e.target.value) })
                }
                fullWidth
                size="small"
              />
            ) : (
              formatPhoneNumber(phoneNumber) || "Не указан"
            )}
          </td>
        </tr>
        <tr>
          <th>Роль</th>
          <td><strong>{getRoleLabel(role)}</strong></td>
          </tr>
        <tr>
          <th>Автомобили</th>
          <td>
            {carDetails.length > 0 ? (
              <div className="chip-container">
                {carDetails.map((car) => (
                  <Chip
                    key={car.id}
                    label={`${car.brand} ${car.model} (${car.licensePlate || "Нет номера"})`}
                    onClick={() => handleChipClick(car.id)}
                    onDelete={
                      isEditing
                        ? () =>
                            handleDeleteCar(
                              id,
                              car.id,
                              setFormData,
                              setCarDetails,
                              setNotification
                            )
                        : undefined
                    }
                    color="primary"
                    variant={isEditing ? "outlined" : "default"}
                  />
                ))}
              </div>
            ) : (
              <Typography>Автомобилей нет.</Typography>
            )}
          </td>
        </tr>
        <tr>
          <th>Дата создания</th>
          <td>{new Date(createdAt).toLocaleString()}</td>
        </tr>
        <tr>
          <th>Последнее обновление</th>
          <td>{new Date(updatedAt).toLocaleString()}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default UserTable;
