import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  loadUserData,
  handleSaveUserData,
  handleAddCarToUser,
  handleDeleteCar,
} from "./components/userPageHandlers";
import Notification from "../../components/Notification/Notification";
import { Typography, CircularProgress, IconButton } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CarSearchModal from "./components/CarSearchModal";
import UserTable from "./components/UserTable";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import "./UserPage.css";

const UserPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const [carDetails, setCarDetails] = useState([]);
  const [notification, setNotification] = useState({
    open: false,
    severity: "info",
    message: "",
  });
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!id) {
      setNotification({
        open: true,
        severity: "error",
        message: "Некорректный параметр ID пользователя.",
      });
      setLoading(false);
      return;
    }

    loadUserData(id, setUser, setFormData, setCarDetails, setNotification, setLoading);
  }, [id]);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleChipClick = (carId) => {
    if (!isEditing) {
      navigate(`/admin/car/${carId}`);
    }
  };

  const handleCancelEdit = () => {
    // Перезагрузка данных с сервера при отмене изменений
    setLoading(true);
    loadUserData(id, setUser, setFormData, setCarDetails, setNotification, setLoading);
    setIsEditing(false);
  };

  if (loading) {
    return (
      <div className="user-loader-container">
        <CircularProgress className="user-loader" />
        <Typography>Загрузка данных пользователя...</Typography>
      </div>
    );
  }

  if (!user) {
    return (
      <>
        <Notification
          severity="error"
          message="Пользователь не найден."
          open={notification.open}
          onClose={() => setNotification({ ...notification, open: false })}
        />
        <Typography variant="h6" className="user-error">
          Пользователь не найден.
        </Typography>
      </>
    );
  }

  return (
    <div className="user-page-container">
      <Notification
        severity={notification.severity}
        message={notification.message}
        open={notification.open}
        onClose={() => setNotification({ ...notification, open: false })}
      />

      <div className="user-header">
        <Typography variant="h5" className="user-page-title">
          {formData.username}
        </Typography>
        <div className="header-buttons">
          {isEditing ? (
            <>
              <IconButton
                color="primary"
                onClick={() => handleSaveUserData(id, formData, setUser, setNotification, setIsEditing)}
                title="Сохранить"
              >
                <SaveIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={handleModalOpen}
                title="Добавить автомобиль"
              >
                <AddCircleIcon />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={handleCancelEdit}
                title="Отменить"
              >
                <CancelIcon />
              </IconButton>
            </>
          ) : (
            <IconButton color="secondary" onClick={() => setIsEditing(true)} title="Редактировать">
              <EditNoteIcon />
            </IconButton>
          )}
        </div>
      </div>

      <UserTable
        formData={formData}
        carDetails={carDetails || []}
        isEditing={isEditing}
        handleChipClick={handleChipClick}
        handleDeleteCar={handleDeleteCar}
        setFormData={setFormData}
        formatPhoneNumber={formatPhoneNumber}
        id={id}
        setNotification={setNotification}
        setCarDetails={setCarDetails}
      />

      <CarSearchModal
        open={modalOpen}
        onClose={handleModalClose}
        onAddCar={(carId) => handleAddCarToUser(id, carId, setFormData, setCarDetails, setNotification)}
      />
    </div>
  );
};

export default UserPage;
