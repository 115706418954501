import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../auth/authService";
import { fetchCurrentUser } from "../../services/userService";
import UserContext from "../../context/UserContext";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import errorMessages from "../../utils/errorMessages";
import "./LoginPage.css";

const LoginPage = () => {
  const [credentials, setCredentials] = useState({ username: "", password: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  // Функция для перенаправления в зависимости от роли
  const redirectToRolePage = useCallback(
    (role) => {
      switch (role) {
        case "ADMIN":
          navigate("/admin");
          break;
        case "MANAGER":
          navigate("/manager");
          break;
        case "USER":
          navigate("/client");
          break;
        default:
          console.error("Неизвестная роль пользователя:", role);
      }
    },
    [navigate]
  );

  // Автоматический редирект для авторизованных пользователей
  useEffect(() => {
    const checkAndRedirect = async () => {
      if (user) {
        redirectToRolePage(user.role);
        return;
      }

      try {
        const currentUser = await fetchCurrentUser(); // Проверяем текущего пользователя
        if (currentUser) {
          setUser(currentUser); // Устанавливаем пользователя в контексте
          redirectToRolePage(currentUser.role); // Редиректим на соответствующую роль
        }
      } catch {
        console.warn("Пользователь не авторизован.");
      }
    };

    checkAndRedirect();
  }, [user, setUser, redirectToRolePage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      await login(credentials.username, credentials.password);
      const currentUser = await fetchCurrentUser();
      setUser(currentUser);
      redirectToRolePage(currentUser.role);
    } catch (err) {
      console.error("Ошибка авторизации:", err);
      const errorMessage = errorMessages[err.status] || errorMessages.DEFAULT;
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <Typography variant="h4" className="login-title">
          Войти
        </Typography>
        <div className="error-container">
          {error && (
            <Typography variant="body2" color="error" className="error-message">
              {error}
            </Typography>
          )}
        </div>
        <TextField
          label="Имя пользователя"
          name="username"
          value={credentials.username}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Пароль"
          name="password"
          type={showPassword ? "text" : "password"}
          value={credentials.password}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Далее"}
        </Button>
      </form>
    </div>
  );
};

export default LoginPage;