import React from "react";
import { Box, Stack, FormControlLabel, Checkbox } from "@mui/material";

export default function OrderFilters({ filters, onChange }) {
  return (
    <Box className="filter-container">
      <Stack className="stack-horizontal">
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.created}
              onChange={onChange}
              name="created"
            />
          }
          label="На согласовании"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.inProgress}
              onChange={onChange}
              name="inProgress"
            />
          }
          label="В работе"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.done}
              onChange={onChange}
              name="done"
            />
          }
          label="Выполнен"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.stopped}
              onChange={onChange}
              name="stopped"
            />
          }
          label="Приостановлен"
        />
      </Stack>
    </Box>
  );
}