import { apiClient } from "./apiClient";

/**
 * Текущий пользователь
 */
export const fetchCurrentUser = async () => {
  return apiClient("/users/me");
};

/**
 * Создание пользователя
 */
export const createUser = async (userData) => {
  return apiClient("/users", {
    method: "POST",
    body: userData,
  });
};

/**
 * Получение всех пользователей
 */
export const fetchAllUsers = async (page = 0, size = 10, sortDir = "desc") => {
  return apiClient(`/users?page=${page}&size=${size}&sortDir=${sortDir}`);
};

/**
 * Поиск пользователей
 */
export const searchUsers = async (query, page = 0, size = 10, sortDir = "desc") => {
  return apiClient(`/users?query=${query}&page=${page}&size=${size}&sortDir=${sortDir}`);
};

/**
 * Получение пользователя по ID
 */
export const fetchUserById = async (userId) => {
  if (!userId) {
    throw new Error("Не указан ID пользователя");
  }
  return apiClient(`/users/${userId}`, {
    method: "GET",
  });
};

/**
 * Обновление пользователя по ID
 */
export const updateUserById = async (userId, updatedData) => {
  if (!userId) {
    throw new Error("Не указан ID пользователя");
  }
  return apiClient(`/users/${userId}`, {
    method: "PUT",
    body: updatedData,
  });
};