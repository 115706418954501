import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from "./context/UserContext";
import LoginPage from "./pages/LoginPage/LoginPage";
import ReceptionistPage from "./pages/ManagerPage/ManagerPage";
import AdminPage from "./pages/AdminPage/AdminPage";
import AddUserPage from "./pages/AddUserPage/AddUserPage";
import AddOrderPage from "./pages/AddOrderPage/AddOrderPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import AddCarPage from "./pages/AddCarPage/AddCarPage";
import CarPage from "./pages/CarPage/CarPage";
import OrderPage from "./pages/OrderPage/OrderPage";
import UserPage from "./pages/UserPage/UserPage";
import ProtectedRoute from "./components/ProtectedRoute";

const App = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          {/* Публичные маршруты */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/error" element={<ErrorPage />} />

          {/* ADMIN маршруты */}
          <Route element={<ProtectedRoute allowedRoles={["ADMIN"]} />}>
            <Route path="/admin">
              <Route index element={<AdminPage />} />
              <Route path="add-user" element={<AddUserPage />} />
              <Route path="add-car" element={<AddCarPage />} />
              <Route path="user/:id" element={<UserPage />} />
              <Route path="car/:carId" element={<CarPage />} />
              <Route path="order/:orderId" element={<OrderPage />} />
            </Route>
          </Route>

          {/* MANAGER маршруты */}
          <Route element={<ProtectedRoute allowedRoles={["MANAGER", "ADMIN"]} />}>
            <Route path="/manager">
              <Route index element={<ReceptionistPage />} />
              <Route path="add-order" element={<AddOrderPage />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;
