import { handleApiError } from "../utils/handleApiError"; // Импорт общего обработчика

/**
 * Вход в систему
 * @param {string} username - Имя пользователя.
 * @param {string} password - Пароль.
 * @throws {Error} - Если авторизация не удалась.
 */
export const login = async (username, password) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
    credentials: "include", // Включение передачи куки
  });

  if (!response.ok) {
    await handleApiError(response);
  }
};

/**
 * Обновление токена
 * @returns {Promise<boolean>} - Возвращает true, если токен успешно обновлён.
 * @throws {Error} - Если обновление не удалось.
 */
export const refreshToken = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
    method: "POST",
    credentials: "include",
  });

  if (!response.ok) {
    if (response.status === 401) {
      console.warn("Рефреш токен недействителен. Пользователь должен войти снова.");
      return false;
    }
    await handleApiError(response);
  }

  return true;
};

/**
 * Выход из системы
 * @throws {Error} - Если запрос на выход не удался.
 */
export const logout = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
    method: "POST",
    credentials: "include", // Включение передачи куки
  });

  if (!response.ok) {
    await handleApiError(response);
  }
};

/**
 * Проверка аутентификации пользователя
 * @returns {Promise<boolean>} - Возвращает true, если пользователь аутентифицирован.
 */
export const checkAuth = async () => {
  try {
    const refreshed = await refreshToken();
    return refreshed;
  } catch {
    console.warn("Пользователь не аутентифицирован или рефреш токен недействителен.");
    return false;
  }
};

/**
 * Выполняет безопасный API-запрос с автоматическим обновлением токена при необходимости.
 * @param {string} endpoint - URL API (относительный путь).
 * @param {Object} options - Параметры запроса.
 * @returns {Promise<any>} - Ответ API в формате JSON.
 * @throws {Error} - Если запрос не удался.
 */
export const fetchWithAuth = async (endpoint, options = {}) => {
  const performFetch = async () =>
    fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
      ...options,
      credentials: "include",
    });

  let response = await performFetch();

  if (response.status === 401) {
    console.warn("Токен истёк. Попытка обновить токен...");
    const refreshed = await refreshToken();

    if (refreshed) {
      response = await performFetch();
    } else {
      throw new Error("Не удалось обновить токен. Пользователь должен войти снова.");
    }
  }

  if (!response.ok) {
    await handleApiError(response);
  }

  return response.json();
};
