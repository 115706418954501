import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUser } from "../../services/userService";
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Stack,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import "./AddUserPage.css";

const AddUserPage = () => {
  const [formData, setFormData] = useState({
    lastName: "",
    firstName: "",
    middleName: "",
    phoneNumber: "",
    role: "USER",
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: name === "phoneNumber" ? formatPhoneNumber(value) : value, // Форматируем телефон
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, middleName, phoneNumber, username, password } = formData;

    if (!firstName.trim() || !lastName.trim() || !middleName.trim() || !phoneNumber.trim() || !username.trim() || !password.trim()) {
      alert("Пожалуйста, заполните все поля!");
      return;
    }

    try {
      setLoading(true);
      await createUser(formData);
      navigate(-1);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Box className="add-user-page-container">
      <Typography variant="h6" gutterBottom>
        Создание нового пользователя
      </Typography>
      {error && <Typography color="error" className="error-message">{error}</Typography>}
      <Box component="form" onSubmit={handleSubmit} className="form-container">
        <TextField
          name="lastName"
          label="Фамилия"
          value={formData.lastName}
          onChange={handleChange}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
        <TextField
          name="firstName"
          label="Имя"
          value={formData.firstName}
          onChange={handleChange}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
        <TextField
          name="middleName"
          label="Отчество"
          value={formData.middleName}
          onChange={handleChange}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
        <TextField
          name="phoneNumber"
          label="Телефон"
          value={formData.phoneNumber}
          onChange={handleChange}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
        <FormControl fullWidth size="small">
          <InputLabel id="role-select-label">Роль</InputLabel>
          <Select
            labelId="role-select-label"
            name="role"
            value={formData.role}
            label="Роль"
            onChange={handleChange}
            required
          >
            <MenuItem value="ADMIN">Админ</MenuItem>
            <MenuItem value="MANAGER">Менеджер</MenuItem>
            <MenuItem value="USER">Клиент</MenuItem>
          </Select>
        </FormControl>
        <TextField
          name="username"
          label="Имя для входа"
          value={formData.username}
          onChange={handleChange}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
        <TextField
          name="password"
          label="Пароль"
          type={showPassword ? "text" : "password"}
          value={formData.password}
          onChange={handleChange}
          variant="outlined"
          size="small"
          required
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Stack direction="row" spacing={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            fullWidth
          >
            {loading ? <CircularProgress size={20} color="inherit" /> : "Сохранить"}
          </Button>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            fullWidth
          >
            Отмена
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default AddUserPage;