const errorMessages = {
  401: "Неправильный логин или пароль.",
  403: "У вас нет прав для выполнения этого действия.",
  404: "Страница не найдена. Проверьте адрес или вернитесь на главную.",
  500: "Внутренняя ошибка сервера. Попробуйте позже.",
  400: "Некорректный запрос. Проверьте введённые данные.",
  NETWORK_ERROR: "Не удалось подключиться к серверу. Проверьте ваше интернет-соединение.",
  SESSION_EXPIRED: "Ваша сессия истекла. Пожалуйста, войдите снова.",
  DEFAULT: "Произошла ошибка.",
};

export default errorMessages;