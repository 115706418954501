import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { fetchCurrentUser } from "../services/userService";
import errorMessages from "../utils/errorMessages";
import { Box, CircularProgress } from "@mui/material";

/**
 * ProtectedRoute компонент для защиты маршрутов.
 *
 * Правила доступа:
 * - ADMIN: имеет доступ ко всем страницам (админ, менеджер, клиент).
 * - MANAGER: имеет доступ к страницам менеджера и клиентам.
 * - USER: имеет доступ только к своим страницам клиента.
 *
 * @param {string[]} allowedRoles - Массив ролей, которым разрешён доступ к маршруту.
 */
const ProtectedRoute = ({ allowedRoles }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: null,
    user: null, 
    error: null,
  });
  
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;

    const checkAuthentication = async () => {
      try {
        console.log("Проверка аутентификации...");
        const user = await fetchCurrentUser();
        console.log("Полученные данные пользователя:", user);

        if (isMounted) {
          setAuthState({ isAuthenticated: true, user, error: null });
        }
      } catch (err) {
        const errorMessage = err.response?.data?.message || errorMessages.UNAUTHORIZED;
        console.error("Ошибка проверки аутентификации:", errorMessage);

        if (isMounted) {
          setAuthState({
            isAuthenticated: false,
            user: null,
            error: errorMessage,
          });
        }
      }
    };

    checkAuthentication();

    return () => {
      isMounted = false;
    };
  }, []);

  // Индикатор загрузки, пока идёт проверка аутентификации
  if (authState.isAuthenticated === null) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Если пользователь не аутентифицирован
  if (!authState.isAuthenticated) {
    console.warn("Пользователь не аутентифицирован, перенаправление на страницу входа.");
    return (
      <Navigate
        to="/"
        state={{ from: location, message: authState.error }}
      />
    );
  }

  const userRole = authState.user.role;

  // Проверяем, есть ли у пользователя разрешение на доступ к маршруту
  const hasAccess =
    allowedRoles.includes(userRole) || (userRole === "ADMIN" && allowedRoles.includes("MANAGER"));

  if (!hasAccess) {
    console.warn(`Роль пользователя (${userRole}) не соответствует допустимым ролям:`, allowedRoles);
    return (
      <Navigate
        to="/error"
        state={{ message: errorMessages.FORBIDDEN }}
      />
    );
  }

  console.log(`Пользователь с ролью ${userRole} имеет доступ к маршруту.`);
  return <Outlet />;
};

export default ProtectedRoute;