import React from "react";
import { Typography } from "@mui/material";
import StatusBadge from "../StatusBadge/StatusBadge"; 

const OrderData = ({ item }) => (
  <>
    <Typography>
      <strong>Номер заказа:</strong> {item.orderNumber || "Не указан"}
    </Typography>
    <Typography>
      <strong>Клиент:</strong>{" "}
      {item.client
        ? `${item.client.lastName} ${item.client.firstName} ${
            item.client.middleName || ""
          }`
        : "Не указан"}
    </Typography>
    <Typography>
      <strong>Автомобиль:</strong> {item.car?.brand} {item.car?.model}{" "}
      ({item.car?.licensePlate || "Не указан"})
    </Typography>
    <Typography>
      <strong>Менеджер:</strong> {item.manager?.firstName} {item.manager?.lastName}
    </Typography>
    <Typography>
      <strong>Дата создания:</strong>{" "}
      {new Date(item.createdAt).toLocaleString()}
    </Typography>
    <Typography>
      <strong>Статус:</strong> <StatusBadge status={item.orderStatus} />
    </Typography>
  </>
);

export default OrderData;