export const formatPhoneNumber = (input) => {
  const digits = input.replace(/\D/g, ""); // Удаляем все, кроме цифр

  let formatted = "+7";

  if (digits.startsWith("8")) {
    formatted = "+7";
    input = digits.slice(1); // Убираем первую цифру "8"
  } else if (digits.startsWith("9")) {
    formatted = "+7";
    input = digits; // Добавляем "+7", если начинается с "9"
  } else if (digits.startsWith("7")) {
    formatted = "+7";
    input = digits.slice(1); // Если начинается с "7", то пропускаем первый символ
  } else {
    input = digits; // Все остальное
  }

  if (input.length > 0) {
    formatted += ` (${input.slice(0, 3)}`;
  }
  if (input.length > 3) {
    formatted += `) ${input.slice(3, 6)}`;
  }
  if (input.length > 6) {
    formatted += `-${input.slice(6, 8)}`;
  }
  if (input.length > 8) {
    formatted += `-${input.slice(8, 10)}`;
  }

  return formatted.trim();
};