import React from "react";
import PropTypes from "prop-types";
import "./OrderTable.css";

const OrderTable = ({ title, rows }) => {
  return (
    <div className="order-table-container">
      {title && <div className="table-title">{title}</div>}
      <table className="order-table">
        <tbody>
          {rows.map(({ label, value }, index) => (
            <tr key={index}>
              <th>{label}</th>
              <td>
                {typeof value === "string" || typeof value === "number" ? (
                  <span className="order-table-text">{value}</span>
                ) : (
                  value
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

OrderTable.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
    })
  ).isRequired,
};

export default OrderTable;
