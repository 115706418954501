import { useState, useCallback } from "react";
import { searchUsers } from "../services/userService";
import { searchCars } from "../services/carService";
import { fetchOrders } from "../services/orderService";

/**
 * Хук для загрузки и фильтрации данных.
 *
 * @param {Object} params
 * @param {string} params.dataType - Тип данных: "orders", "clients", "cars", "users"
 * @param {string} params.searchTerm - Текст поиска
 * @param {number} params.currentPage - Текущая страница (нумерация с 1)
 * @param {number} params.pageSize - Количество элементов на страницу
 * @param {Object} params.orderStatusFilters - Фильтры по статусам заказов
 * @param {Object} [params.roleFilters] - Фильтры ролей (для "users"), если нужно
 * @param {Function} params.onError - Колбэк при ошибке
 */
export default function useFetchData({
  dataType,
  searchTerm,
  currentPage,
  pageSize,
  orderStatusFilters,
  roleFilters,
  onError,
}) {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleSearch = useCallback(async () => {
    setLoading(true);
    try {
      let response;

      // --- Раздел "Клиенты" ---
      // Локально фильтруем по role === "USER"
      if (dataType === "clients") {
        response = await searchUsers(searchTerm, currentPage - 1, pageSize);
        const serverUsers = response.content || [];
        setTotalPages(response.totalPages || 1);

        // Локально оставляем только тех, у кого role === "USER"
        const filtered = serverUsers.filter((u) => u.role === "USER");
        setData(filtered);

      // --- Раздел "Все пользователи" ---
      // Локально фильтруем по ролям (roleFilters).
      } else if (dataType === "users") {
        response = await searchUsers(searchTerm, currentPage - 1, pageSize);
        const serverUsers = response.content || [];
        setTotalPages(response.totalPages || 1);

        // Чекбоксы (roleFilters) 
        let filtered = serverUsers;
        if (roleFilters) {
          filtered = filtered.filter(u => roleFilters[u.role]);
        }
        setData(filtered);

      // --- Раздел "Автомобили" ---
      } else if (dataType === "cars") {
        response = await searchCars(searchTerm, currentPage - 1, pageSize);
        setData(response.content || []);
        setTotalPages(response.totalPages || 1);

      // --- Раздел "Заказы" ---
      } else if (dataType === "orders") {
        response = await fetchOrders(currentPage - 1, pageSize);
        const serverOrders = response.orders || [];
        setTotalPages(response.totalPages || 1);

        // Фильтрация по статусам
        let filtered = serverOrders.filter((o) => {
          const st = o.orderStatus || "";
          return (
            (orderStatusFilters.created && st === "CREATED") ||
            (orderStatusFilters.inProgress && st === "IN_PROGRESS") ||
            (orderStatusFilters.done && st === "DONE") ||
            (orderStatusFilters.stopped && st === "STOPPED")
          );
        });

        // Фильтрация по номеру заказа
        if (searchTerm.trim()) {
          const lower = searchTerm.trim().toLowerCase();
          filtered = filtered.filter((o) =>
            (o.orderNumber || "").toLowerCase().includes(lower)
          );
        }

        setData(filtered);
      }
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
      onError?.(error);
    } finally {
      setLoading(false);
    }
  }, [
    dataType,
    searchTerm,
    currentPage,
    pageSize,
    orderStatusFilters,
    roleFilters,
    onError,
  ]);

  return {
    data,
    totalPages,
    loading,
    handleSearch,
    setData,
  };
}