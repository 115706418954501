export const getStatusClass = (status) => {
  switch (status) {
    case "DONE":
      return "status-completed"; // Статус выполнен
    case "IN_PROGRESS":
      return "status-in-progress"; // Статус в работе
    case "CREATED":
      return "status-pending"; // Статус создан
    case "STOPPED":
      return "status-stopped"; // Статус приостановлен
    default:
      return "";
  }
};

export const getStatusLabel = (status) => {
  switch (status) {
    case "DONE":
      return "Выполнен";
    case "IN_PROGRESS":
      return "В работе";
    case "CREATED":
      return "Записан/ожидание согласования";
    case "STOPPED":
      return "Приостановлен";
    default:
      return "Неизвестно";
  }
};
