import { apiClient } from "./apiClient";

export const fetchOrders = async (page = 0, size = 10) => {
  const data = await apiClient(`/orders?page=${page}&size=${size}&sortDir=desc`);
  return {
    orders: data.content,
    totalPages: data.totalPages,
    totalElements: data.totalElements,
  };
};

export const createOrder = async (orderData) => {
  return apiClient("/orders", {
    method: "POST",
    body: orderData,
  });
};

export const fetchOrderById = async (orderId) => {
  return apiClient(`/orders/${orderId}`);
};

export const updateOrder = async (orderId, updatedData) => {
  return apiClient(`/orders/${orderId}`, {
    method: "PUT",
    body: updatedData,
  });
};