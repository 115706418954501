/**
 * Расшифровка ролей пользователей.
 * @param {string} role - Код роли (например, USER, MANAGER, ADMIN).
 * @returns {string} - Название роли
 */
export const getRoleLabel = (role) => {
    switch (role) {
      case "USER":
        return "Клиент";
      case "MANAGER":
        return "Менеджер";
      case "ADMIN":
        return "Админ";
      default:
        return "Неизвестная роль";
    }
  };
  