import React, { useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  Pagination,
  Stack,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { fetchCurrentUser } from "../../services/userService";
import { logout } from "../../auth/authService";
import UserContext from "../../context/UserContext";

import useFetchData from "../../hooks/useFetchData";
import AdminHeader from "../../components/AdminPageComponents/AdminHeader";
import OrderFilters from "../../components/AdminPageComponents/OrderFilters";
import OrderData from "../../components/DataComponents/OrderData";
import CarData from "../../components/DataComponents/CarData";
import UserData from "../../components/DataComponents/UserData";
import "./AdminPage.css";

const AdminPage = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const [searchTerm, setSearchTerm] = React.useState("");
  const [dataType, setDataType] = React.useState("orders");
  const [currentUser, setCurrentUser] = React.useState(null);

  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 10;

  const [orderStatusFilters, setOrderStatusFilters] = React.useState({
    created: true,
    inProgress: true,
    done: false,
    stopped: false,
  });

  const [roleFilters, setRoleFilters] = React.useState({
    USER: true,
    MANAGER: true,
    ADMIN: true,
  });

  const handleError = useCallback((error) => {
    console.error(error);
    navigate("/error", { state: { message: "Произошла ошибка при загрузке данных." } });
  }, [navigate]);

  const { data, totalPages, loading, handleSearch } = useFetchData({
    dataType,
    searchTerm,
    currentPage,
    pageSize,
    orderStatusFilters,
    roleFilters,
    onError: handleError,
  });

  useEffect(() => {
    const loadCurrentUser = async () => {
      try {
        const user = await fetchCurrentUser();
        setCurrentUser(user);
      } catch {
        navigate("/error", {
          state: { message: "У вас нет доступа к этой странице." },
        });
      }
    };
    loadCurrentUser();
  }, [navigate]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const handlePageChange = (_, value) => setCurrentPage(value);

  const handleLogout = async () => {
    try {
      await logout();
      setUser(null);
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Ошибка при логауте:", error);
      alert("Не удалось выполнить выход. Попробуйте снова.");
    }
  };

  const handleItemClick = (itemId) => {
    if (dataType === "orders") {
      navigate(`/admin/order/${itemId}`);
    } else if (dataType === "cars") {
      navigate(`/admin/car/${itemId}`);
    } else {
      navigate(`/admin/user/${itemId}`);
    }
  };

  const handleRoleFilterChange = (event) => {
    setRoleFilters((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <Box className="admin-page">
      <AdminHeader currentUser={currentUser} onLogout={handleLogout} />

      <Box className="content-container">
        <TextField
          fullWidth
          label={dataType === "orders" ? "Поиск по номеру заказа" : "Поиск"}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          variant="outlined"
          size="small"
          className="search-field"
        />

        <FormControl fullWidth>
          <Select value={dataType} onChange={(e) => {
            setDataType(e.target.value);
            setCurrentPage(1);
          }}>
            <MenuItem value="orders">Заказы</MenuItem>
            <MenuItem value="clients">Клиенты</MenuItem>
            <MenuItem value="cars">Автомобили</MenuItem>
            <MenuItem value="users">Все пользователи</MenuItem>
          </Select>
        </FormControl>

        {dataType === "users" && (
          <Box className="filter-container">
            <Stack className="stack-horizontal">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={roleFilters.USER}
                    onChange={handleRoleFilterChange}
                    name="USER"
                  />
                }
                label="Клиент"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={roleFilters.MANAGER}
                    onChange={handleRoleFilterChange}
                    name="MANAGER"
                  />
                }
                label="Менеджер"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={roleFilters.ADMIN}
                    onChange={handleRoleFilterChange}
                    name="ADMIN"
                  />
                }
                label="Админ"
              />
            </Stack>
          </Box>
        )}

        {dataType === "orders" && (
          <OrderFilters filters={orderStatusFilters} onChange={(e) => {
            setOrderStatusFilters((prev) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }} />
        )}

        <Box className="results-container">
          {loading ? (
            <Box className="loading-container">
              <CircularProgress />
            </Box>
          ) : data?.length > 0 ? (
            data.map((item) => (
              <Box
                key={item.id}
                className="result-preview clickable"
                onClick={() => handleItemClick(item.id)}
              >
                {(dataType === "clients" || dataType === "users") && (
                  <UserData item={item} dataType={dataType} />
                )}
                {dataType === "orders" && <OrderData item={item} />}
                {dataType === "cars" && <CarData item={item} />}
              </Box>
            ))
          ) : (
            <Typography>Нет данных для отображения.</Typography>
          )}
        </Box>

        <Stack spacing={2} alignItems="center" className="pagination-container">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            shape="rounded"
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default AdminPage;