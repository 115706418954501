import React from "react";
import { Typography } from "@mui/material";

const CarData = ({ item }) => (
  <>
    <Typography>
      <strong>Марка:</strong> {item.brand}
    </Typography>
    <Typography>
      <strong>Модель:</strong> {item.model}
    </Typography>
    <Typography>
      <strong>Гос.номер:</strong> {item.licensePlate}
    </Typography>
    <Typography>
      <strong>VIN:</strong> {item.vin}
    </Typography>
  </>
);

export default CarData;