import React from "react";
import PropTypes from "prop-types";
import "./StatusBadge.css";

const StatusBadge = ({ status }) => {
  const getStatusClass = (status) => {
    switch (status) {
      case "CREATED":
        return "badge-created";
      case "IN_PROGRESS":
        return "badge-in-progress";
      case "DONE":
        return "badge-done";
      case "STOPPED":
        return "badge-stopped";
      default:
        return "badge-default";
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case "CREATED":
        return "Записан/Ожидание согласования";
      case "IN_PROGRESS":
        return "В работе";
      case "DONE":
        return "Выполнен";
      case "STOPPED":
        return "Приостановлен";
      default:
        return "Неизвестно";
    }
  };

  return <span className={`status-badge ${getStatusClass(status)}`}>{getStatusLabel(status)}</span>;
};

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusBadge;
