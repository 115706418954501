import { apiClient } from "./apiClient";

export const createCar = async (carData) => {
  return apiClient("/cars", {
    method: "POST",
    body: carData,
  });
};

export const fetchCarById = async (carId) => {
  return apiClient(`/cars/${carId}`);
};

export const searchCars = async (query, page = 0, size = 10, sortDir = "desc") => {
  return apiClient(`/cars?query=${query}&page=${page}&size=${size}&sortDir=${sortDir}`);
};

export const updateCar = async (carId, carData) => {
  return apiClient(`/cars/${carId}`, {
    method: "PUT",
    body: carData,
  });
};

export const addCarToUser = async (userId, carId) => {
  return apiClient(`/users/${userId}/cars/${carId}`, {
    method: "POST",
  });
};

export const removeCarFromUser = async (userId, carId) => {
  if (!userId || !carId) {
    throw new Error("Необходимо указать userId и carId");
  }

  return apiClient(`/users/${userId}/cars/${carId}`, {
    method: "DELETE",
  });
};