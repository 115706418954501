import React, { useState } from "react";
import { Typography, Button, Menu, MenuItem, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DriveEtaIcon from "@mui/icons-material/DriveEta";

const AdminHeader = ({ currentUser, onLogout }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleLogoutClick = async () => {
    if (window.confirm("Вы действительно хотите выйти?")) {
      await onLogout();
    }
  };

  return (
    <div className="admin-header">
      <Typography variant="h6" onClick={handleLogoutClick} className="header-left">
        {currentUser
          ? `${currentUser.firstName} ${currentUser.lastName}`
          : <CircularProgress size={20} />
        }
      </Typography>
      <Button
        onClick={handleMenuOpen}
        variant="contained"
        className="menu-button"
        endIcon={<KeyboardArrowDownIcon />}
      >
        Создать
      </Button>
      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
        <MenuItem onClick={() => navigate("/admin/add-user")}>
          <PersonAddIcon fontSize="small" /> Создать пользователя
        </MenuItem>
        <MenuItem onClick={() => navigate("/manager/add-order")}>
          <NoteAddIcon fontSize="small" /> Создать заказ
        </MenuItem>
        <MenuItem onClick={() => navigate("/admin/add-car")}>
          <DriveEtaIcon fontSize="small" /> Создать авто
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AdminHeader;