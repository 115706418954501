import React, { useState, useEffect, useCallback } from "react";
import { Modal, Box, TextField, Typography, CircularProgress } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { searchCars } from "../../../services/carService";
import "./CarSearchModal.css";

const CarSearchModal = ({ userCarIds, onAddCar, open, onClose }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  // Поиск
  const handleSearch = useCallback(async () => {
    if (searchQuery.trim() === "") {
      setSearchResults([]);
      return;
    }
    setLoading(true);
    try {
      const result = await searchCars(searchQuery);
      setSearchResults(result.cars.filter((car) => !userCarIds?.includes(car.id)));
    } catch (error) {
      console.error("Ошибка поиска автомобилей:", error);
    } finally {
      setLoading(false);
    }
  }, [searchQuery, userCarIds]);

  // Дебаунс для поиска при вводе текста
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch();
    }, 500); // 500 мс задержка
    return () => clearTimeout(delayDebounceFn);
  }, [handleSearch]);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="car-modal-title">
      <Box className="car-modal-container">
        <Box className="modal-header">
          <Typography id="car-modal-title" variant="h6">
            Добавить автомобиль
          </Typography>
          <button onClick={onClose} className="close-button">
            <CloseIcon />
          </button>
        </Box>
        <TextField
          fullWidth
          variant="outlined"
          label="Введите госномер или VIN"
          value={searchQuery}
          onChange={handleInputChange}
          placeholder="Поиск автомобиля"
          className="search-input"
        />
        <Box className="car-results-container">
          {loading ? (
            <Box className="loading-container">
              <CircularProgress />
              <Typography>Загрузка...</Typography>
            </Box>
          ) : (
            <>
              {searchResults.length > 0 ? (
                searchResults.map((car) => (
                  <Box key={car.id} className="car-result">
                    <Typography className="car-result-text">
                      {car.brand} {car.model} ({car.licensePlate || "Нет номера"})
                    </Typography>
                    <AddCircleOutlineIcon
                      className="add-icon"
                      onClick={() => onAddCar(car.id)}
                    />
                  </Box>
                ))
              ) : (
                searchQuery && <Typography className="no-results">Нет результатов</Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CarSearchModal;