import React from "react";
import { Typography } from "@mui/material";
import { getRoleLabel } from "../../utils/roleUtils";

const UserData = ({ item, dataType }) => (
  <>
    {/* Общие поля для клиентов и пользователей */}
    <Typography>
      <strong>Фамилия:</strong> {item.lastName}
    </Typography>
    <Typography>
      <strong>Имя:</strong> {item.firstName}
    </Typography>
    <Typography>
      <strong>Отчество:</strong> {item.middleName || "Не указано"}
    </Typography>
      <Typography>
        <strong>Телефон:</strong> {item.phoneNumber || "Не указан"}
      </Typography>

    {/* Поле "Роль" отображается только для раздела "Все пользователи" */}
    {dataType === "users" && (
      <Typography>
        <strong>Роль:</strong> {getRoleLabel(item.role)}
      </Typography>
    )}
  </>
);

export default UserData;