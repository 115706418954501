import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  TextField,
  Button,
  CircularProgress,
  Stack,
  Autocomplete,
} from "@mui/material";
import { createUser, fetchCurrentUser } from "../../services/userService";
import { createCar, addCarToUser } from "../../services/carService";
import { createOrder }  from "../../services/orderService";
import { generateRandomUsername, generateRandomPassword } from "../../utils/randomGenerators";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import Notification from "../../components/Notification/Notification";
import carBrandsAndModels from "../../data/carBrandsAndModels.json";
import carColors from "../../data/carColors.json";
import "./AddOrderPage.css";

const AddOrderPage = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);

  const [formData, setFormData] = useState({
    lastName: "",
    firstName: "",
    middleName: "",
    phoneNumber: "",
    carBrand: "",
    carModel: "",
    carLicensePlate: "",
    carVIN: "",
    year: "",
    color: "",
    mileage: "",
    orderNumber: "",
    reason: "",
    price: "",
    comment: "",
  });

  const [errors, setErrors] = useState({});
  const [models, setModels] = useState([]);
  const [saving, setSaving] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    severity: "info",
    message: "",
  });

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await fetchCurrentUser();
        setCurrentUser(user);
      } catch (err) {
        console.error("Ошибка при загрузке текущего пользователя:", err);
        setNotification({
          open: true,
          severity: "error",
          message: "Не удалось загрузить данные текущего пользователя.",
        });
      }
    };
    fetchUser();
  }, []);

  const validateNumberField = (name, value) => {
    if (!/^\d*$/.test(value)) {
      return "Поле должно содержать только цифры";
    }
    return "";
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (["year", "mileage", "orderNumber", "price"].includes(name)) {
      const error = validateNumberField(name, value);
      setErrors((prev) => ({ ...prev, [name]: error }));
    }
    if (name === "phoneNumber") {
      setFormData((prev) => ({ ...prev, [name]: formatPhoneNumber(value) }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAutocompleteChange = (name, newValue) => {
    if (typeof newValue === "string") {
      setFormData((prev) => ({ ...prev, [name]: newValue }));
    } else if (newValue?.inputValue) {
      setFormData((prev) => ({ ...prev, [name]: newValue.inputValue }));
    } else {
      if (name === "carBrand") {
        setFormData((prev) => ({
          ...prev,
          carBrand: newValue?.brand || "",
          carModel: "",
        }));
        setModels(newValue?.models || []);
      } else if (name === "carModel") {
        setFormData((prev) => ({ ...prev, carModel: newValue?.title || "" }));
      } else {
        setFormData((prev) => ({ ...prev, [name]: newValue?.title || "" }));
      }
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const newUser = await createUser({
        lastName: formData.lastName.trim(),
        firstName: formData.firstName.trim(),
        middleName: formData.middleName.trim() || null,
        phoneNumber: formData.phoneNumber.trim() || null,
        role: "USER",
        username: generateRandomUsername(),
        password: generateRandomPassword(),
      });

      const newCar = await createCar({
        brand: formData.carBrand.trim(),
        model: formData.carModel.trim(),
        licensePlate: formData.carLicensePlate.trim() || null,
        vin: formData.carVIN.trim(),
        year: parseInt(formData.year, 10) || null,
        color: formData.color.trim() || null,
        mileage: parseInt(formData.mileage, 10) || 0,
      });

      await addCarToUser(newUser.id, newCar.id);

      await createOrder({
        clientId: newUser.id,
        carId: newCar.id,
        managerId: currentUser?.id,
        orderNumber: parseInt(formData.orderNumber, 10),
        orderStatus: "CREATED",
        service: formData.reason.trim() || "",
        price: parseFloat(formData.price) || 0.0,
        comment: formData.comment.trim() || "",
      });

      setNotification({
        open: true,
        severity: "success",
        message: `Заказ номер ${formData.orderNumber} успешно создан!`,
      });
    } catch (err) {
      console.error("Ошибка при создании заказа:", err);
      setNotification({
        open: true,
        severity: "error",
        message: "Не удалось создать заказ. Попробуйте ещё раз.",
      });
    } finally {
      setSaving(false);
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
    if (notification.severity === "success") {
      navigate(-1);
    }
  };

  const textFields = [
    { label: "Фамилия", name: "lastName", required: true },
    { label: "Имя", name: "firstName", required: true },
    { label: "Отчество", name: "middleName" },
    { label: "Телефон", name: "phoneNumber", required: true },
    { label: "Госномер", name: "carLicensePlate" },
    { label: "VIN", name: "carVIN", required: true },
    { label: "Год", name: "year", inputMode: "numeric" },
    { label: "Пробег (км)", name: "mileage", inputMode: "numeric" },
    { label: "Номер заказа", name: "orderNumber", required: true, inputMode: "numeric" },
    { label: "Тип услуги", name: "reason", multiline: true, rows: 2 },
    { label: "Цена (руб.)", name: "price", inputMode: "numeric" },
    { label: "Комментарий", name: "comment", multiline: true, rows: 3 },
  ];

  const autoCompleteFields = [
    {
      label: "Марка",
      name: "carBrand",
      options: carBrandsAndModels.cars,
      getOptionLabel: (option) => (typeof option === "string" ? option : option.brand),
    },
    {
      label: "Модель",
      name: "carModel",
      options: models.map((model) => ({ title: model })),
      getOptionLabel: (option) => (typeof option === "string" ? option : option.title),
    },
    {
      label: "Цвет",
      name: "color",
      options: carColors.colors.map((color) => ({ title: color })),
      getOptionLabel: (option) => (typeof option === "string" ? option : option.title),
    },
  ];

  return (
    <div className="add-order-page-container">
      <Notification
        severity={notification.severity}
        message={notification.message}
        open={notification.open}
        onClose={handleCloseNotification}
      />

      <Typography variant="h6" gutterBottom>
        Создание нового заказа
      </Typography>

      <form>
        <Stack spacing={3}>
          <Typography variant="subtitle1">Данные клиента</Typography>
          {textFields.slice(0, 4).map((field) => (
            <TextField
              key={field.name}
              fullWidth
              label={field.label}
              name={field.name}
              value={formData[field.name]}
              onChange={handleInputChange}
              error={!!errors[field.name]}
              helperText={errors[field.name]}
              variant="outlined"
              size="small"
              required={field.required || false}
              multiline={field.multiline || false}
              rows={field.rows || 1}
              inputMode={field.inputMode || "text"}
            />
          ))}

          <Typography variant="subtitle1">Данные автомобиля</Typography>
          {autoCompleteFields.map((field) => (
            <Autocomplete
              key={field.name}
              options={field.options}
              getOptionLabel={field.getOptionLabel}
              onChange={(event, newValue) => handleAutocompleteChange(field.name, newValue)}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={field.label}
                  fullWidth
                  error={!!errors[field.name]}
                  helperText={errors[field.name]}
                />
              )}
            />
          ))}
          {textFields.slice(4, 8).map((field) => (
            <TextField
              key={field.name}
              fullWidth
              label={field.label}
              name={field.name}
              value={formData[field.name]}
              onChange={handleInputChange}
              error={!!errors[field.name]}
              helperText={errors[field.name]}
              variant="outlined"
              size="small"
              required={field.required || false}
              multiline={field.multiline || false}
              rows={field.rows || 1}
              inputMode={field.inputMode || "text"}
            />
          ))}

          <Typography variant="subtitle1">Дополнительно</Typography>
          {textFields.slice(8).map((field) => (
            <TextField
              key={field.name}
              fullWidth
              label={field.label}
              name={field.name}
              value={formData[field.name]}
              onChange={handleInputChange}
              error={!!errors[field.name]}
              helperText={errors[field.name]}
              variant="outlined"
              size="small"
              required={field.required || false}
              multiline={field.multiline || false}
              rows={field.rows || 1}
              inputMode={field.inputMode || "text"}
            />
          ))}

          <Stack direction="row" spacing={1}>
            <Button variant="outlined" onClick={handleCancel} fullWidth>
              Отмена
            </Button>
            <Button variant="contained" onClick={handleSave} disabled={saving} fullWidth>
              {saving ? <CircularProgress size={20} /> : "Сохранить"}
            </Button>
          </Stack>
        </Stack>

      </form>
    </div>
  );
};

export default AddOrderPage;